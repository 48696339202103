import { Button,Copy, H6 } from '@phmg/storybook/v1/component';

import S from './styles';
interface CtaCardProps {
    title: string;
    content: string;
    colour: 'green' | 'green2' | 'purple';
    button1Text: string;
    button2Text: string;
}

export default function Cta2Card({ title, content, colour, button1Text, button2Text, ...props }: CtaCardProps) {
  return (
    <S.Card className={colour} {...props}>
      <S.Text>
        <H6 text={title} />
        <Copy>{content}</Copy>
      </S.Text>
      <S.Buttons>
        <Button type="button" label={button1Text} variant='ghost' />
        <Button type="button" label={button2Text} variant='black' />
      </S.Buttons>
    </S.Card>
  );
}
