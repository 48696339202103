import authRequestsSlice from './auth';
import profileRequestsSlice from './profile';
import salesforceRequestsSlice from './salesforce';
import { siteRequestsSlice } from './sites';

export const {
  useGetAllSitesQuery,
  useGetSiteDetailsQuery,
  useSetSiteDetailsMutation,
  useGetSiteCountiesQuery,
  useGetSiteCountryQuery,
  useGetSiteProductionsQuery,
  useGetSiteProductionsFilesQuery,
  useGetDynamicsAccountManagerQuery,
  useGetInvoicesListQuery,
  useLazyGetPDFInvoiceQuery,
} = siteRequestsSlice;

export const { useLoginMutation } = authRequestsSlice;

export const {
  useGetSFUserQuery,
  useGetAccountQuery,
  useGetHeirarchyAccountsQuery,
  useGetAccountManagerQuery,
  useGetCasesQuery,
  useGetCasesByTypeQuery,
  useGetCasesByRecordTypeQuery,
  useGetCaseMembersQuery,
  useGetCaseFilesQuery,
  useGetAccountFilesQuery,
  useGetDownloadUrlQuery,
  useLazyGetDownloadUrlQuery,
  useCreateCaseQuery,
  useLazyCreateCaseQuery,
  useCreateFeedbackQuery,
  useLazyCreateFeedbackQuery,
} = salesforceRequestsSlice;

export const { useGetUserQuery } = profileRequestsSlice;
