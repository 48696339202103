import styled from 'styled-components';
const Hero = styled.div`
      text-align: center;
    color: #fff;
    background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0.3)),
        url('https://phmg-styles.s3.eu-west-2.amazonaws.com/images/Rectangle+(1).jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 320px;
    display: flex;
    justify-content: center;  
    align-items: center;
    flex-direction: column;
    margin: auto;
    
    div{
      display: flex;
    justify-content: center;  
    align-items: center;
    flex-direction: column;
    margin: auto;
    }
`;

export default {
  Hero
};
