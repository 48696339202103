import { useGetDownloadUrlQuery } from '@config/state/globalSlices/apiSlice/requests';
import { AudioPlayer } from '@phmg/storybook/v1/component';
import { H4 } from '@shared/components/Typography';

import S from './styles';

type AudioWithTitleTypes = {
    name: string;
    url: string;
};

export default function AudioWithTitle({ name, url }: AudioWithTitleTypes) {

  // Get Production Download Url
  const {
    data,
    isLoading,
  } = useGetDownloadUrlQuery(
    url,
    { skip: !url }
  );

  return (<>
    {!isLoading && data && (
      <S.AudioContainer>
        <H4>
                    Your Track
        </H4>
        <p>
          {name}
        </p>
        <S.Player>
          <AudioPlayer name={name} url={data} />
        </S.Player>
      </S.AudioContainer>
    )
    }
  </>
  )
}
