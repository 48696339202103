import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

const Card = styled.div`
    background-color: var(--white);
    color: inherit;
    text-decoration: none;
    transition: 0.25s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 2rem;
    width: 100%;
    min-height: 100px;

    h6{
        font-weight: 900;
    }

    &.green {
        background-color: var(--deep-teal);
        color: var(--lime);
    }

    &.green2 {
        background-image: linear-gradient( to right, var(--turquise), var(--lime) );
        color: var(--black);
    }

    &.purple {
        background-color: #380044;
        color: var(--pink);
    }

    @media (max-width: ${size.large}) {
    flex-direction: column;
  }

`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;

    @media (max-width: ${size.large}) {
        width: auto;
  }
`;

const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 35%;
    > * {
            margin-right: 1rem;
        }

    @media (max-width: ${size.large}) {
        width: auto;
        margin-top: 1rem;
        justify-content: flex-start;
        
  }
`;

export default {
  Card,
  Text,
  Buttons
};
