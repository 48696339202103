/* eslint-disable @typescript-eslint/no-unused-vars */
import { getLocationMenuItems } from '@config/locationMenuItems';
import { CaseTypes, RecordTypes } from '@config/state/globalSlices/apiSlice/constants';
import { useGetAccountFilesQuery, useGetAccountQuery, useGetCasesByTypeQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useSiteDetails } from '@hooks/useSiteDetails';
import { Icon } from '@phmg/storybook/v1/component';
import { createNotification } from '@shared/utils/createNotification';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

const Sidebar = () => {
  const { siteId } = useParams();
  const VITE_SF_FF_ENABLED = JSON.parse(import.meta.env.VITE_SF_FF_ENABLED);
  const [hasProductions, setHasProductions] = useState<boolean>(false);

  // User Data
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery();

  const accountId = userData?.salesforceId as string;

  const { siteDetails, isLoading: siteDetailsLoading, siteDetailsisError } = useSiteDetails(siteId);

  // Account Details
  const {
    data: accountDetails,
    isLoading: accountDetailsIsLoading,
    isError: accountDetailsIsError,
  } = useGetAccountQuery(accountId || '', { skip: !accountId || !VITE_SF_FF_ENABLED });
  const accountName = accountDetails?.Name;

  const isLoading = userIsLoading || accountDetailsIsLoading;

  // // AWAITING APPROVAL
  // // Copywriter
  // const {
  //   data: copywriterCaseData,
  //   isLoading: copywriterCaseIsLoading,
  //   isError: copywriterCaseIsError,
  // } = useGetCasesByTypeQuery(
  //   { accountId: accountId, recordType: RecordTypes.COPYWRITER, caseType: CaseTypes.DEMO },
  //   { skip: !accountId || !VITE_SF_FF_ENABLED }
  // );
  // const copywriterCaseNumber = copywriterCaseData?.[0]?.CaseNumber || '';
  // // Music and Voiceover
  // const {
  //   data: productionCaseData,
  //   isLoading: productionCaseIsLoading,
  //   isError: productionCaseIsError,
  // } = useGetCasesByTypeQuery(
  //   { accountId: accountId, recordType: RecordTypes.PRODUCTION, caseType: CaseTypes.DEMO },
  //   { skip: !accountId || !VITE_SF_FF_ENABLED }
  // );
  // const productionCaseNumber = productionCaseData?.[0]?.CaseNumber || '';

  // // APPROVED PRODUCTIONS
  // const {
  //   data: accountFiles,
  //   isLoading: accountFilesIsLoading,
  //   isError: accountFilesIsError,
  // } = useGetAccountFilesQuery(accountName || '', { skip: !accountName || !VITE_SF_FF_ENABLED || !accountId });

  // // No Productions or Cases // Is Loading
  // const isLoading =
  //   userIsLoading &&
  //   siteDetailsLoading &&
  //   accountDetailsIsLoading &&
  //   accountFilesIsLoading &&
  //   copywriterCaseIsLoading &&
  //   productionCaseIsLoading;

  // if ((copywriterCaseNumber || productionCaseNumber || accountFiles) && !hasProductions) {
  //   setHasProductions(true);
  // }

  // // ERROR NOTIFICATIONS
  // useEffect(() => {
  //   if (userIsError && !userIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch user details. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (siteDetailsisError && !siteDetailsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch site details. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (accountDetailsIsError && !accountDetailsIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch account details. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (accountFilesIsError && !accountFilesIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch approved productions. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (productionCaseIsError && !productionCaseIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch awaiting approval productions. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (copywriterCaseIsError && !copywriterCaseIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch scripts. Please try again.',
  //       type: 'error',
  //     });
  //   }
  // }, [
  //   userIsError,
  //   userIsLoading,
  //   siteDetailsisError,
  //   siteDetailsLoading,
  //   accountDetailsIsError,
  //   accountDetailsIsLoading,
  //   accountFilesIsError,
  //   accountFilesIsLoading,
  //   productionCaseIsError,
  //   productionCaseIsLoading,
  //   copywriterCaseIsError,
  //   copywriterCaseIsLoading,
  // ]);

  const locationMenuItems = getLocationMenuItems(siteId!, hasProductions).filter((x) => x.enabled);

  return (
    <S.Container>
      {!isLoading && siteDetails && (
        <S.InnerContainer>
          <S.SideBarMenuWrapper>
            <ul>
              {locationMenuItems.map(({ title, href, color, glyph }) => (
                <S.StyledNavLink to={href} key={title} end target="" color={color}>
                  <li>
                    <Icon glyph={glyph} size={'10px'} />

                    <p>{title}</p>
                  </li>
                </S.StyledNavLink>
              ))}
            </ul>
          </S.SideBarMenuWrapper>

          <S.HelpCentre>
            <a href="https://phmg.com/help-centre/" target="_blank">
              <Icon glyph="flat-help" />
              Help Centre
            </a>
          </S.HelpCentre>
        </S.InnerContainer>
      )}
    </S.Container>
  );
};

export default Sidebar;
