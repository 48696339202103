import styled from 'styled-components';

const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 0;
    margin: auto;
    max-width: 66%;
    text-align:center;
    background-color: #f8f9fa;

    p{
      font-weight: 500;
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
`
const AudioPlayer = styled.div`
color: black;
background-color: white;
padding: 4rem 0px;

@media only screen and (max-width: 600px) {
  padding: 0rem 0px 4rem;
    }
`;

export default {
  SpinnerContainer,
  Container,
  AudioPlayer
};
