import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { TeamMemberType } from '@config/state/globalSlices/apiSlice/types/salesforce';
//@ts-ignore
import { A11y,Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import S from './styles';
import TeamMemberSlide from './TeamMemberSlide';

type TeamCarouselType = {
  teamMembers: TeamMemberType[]
} 

const TeamCarousel = ({ teamMembers }:TeamCarouselType) => {
  const teamMembersArray = Object.values(teamMembers);
  return (
    <S.SwiperWrapper>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={33}
        slidesPerView={1}
        pagination={{ clickable: true }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 33,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 33,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 33,
          },
        }}
      >
        {teamMembersArray?.map((teamMember) => (
          <SwiperSlide key={Math.random() * Math.random() * Math.random() * Math.random() * 1_000_000}>
            <TeamMemberSlide {...teamMember} />
          </SwiperSlide>
        ))}
      </Swiper>
    </S.SwiperWrapper>
  );
};

export { TeamCarousel as default };
