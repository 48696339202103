import styled from 'styled-components';

const BrandValuesContainer = styled.div`
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
padding: 2rem 0 3rem;
margin: auto;
width: 100%;
text-align:center;
color: white;
background-image: linear-gradient(to right, #007CFF, #8033FF);

h4,p{
  margin: 20px auto;
}

@media only screen and (max-width: 600px) {
  flex-direction: column;
  padding: 3rem 2rem;
}
`

const ButtonsContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  margin: 30px auto;

  @media only screen and (max-width: 1250px) {
  flex-direction: column;
  margin: 30px auto;
}
`;

const BrandValueButton = styled.button`
    background: none;
    color: inherit;
    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem 6rem;
    margin: auto;
    font: inherit;
    cursor: pointer;
    outline: inherit;


  @media only screen and (max-width: 1250px) {
    margin: 20px auto;
    width: 100%;
  }
  
`;

export default {
  BrandValuesContainer,
  ButtonsContainer,
  BrandValueButton
};
