import styled from 'styled-components';

const SwiperWrapper = styled.div`
 width: 100%;
 height: 100%;
margin: 50px auto;
padding: 2rem 1rem 3rem;
 

.swiper {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.swiper-slide {
  background: var(--white);
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  color: inherit;
  text-decoration: none;
  transition: 0.25s ease;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 2rem;
}


.swiper-pagination {
    position: absolute;
    bottom: -4rem !important; 
}

.swiper-pagination-bullet{
    width: 50px;
    height: 3px;
    background-color: var(--black);
    border-radius: 0%;
}

`;

const ImageContainer = styled.div`
margin: 20px auto;
height: 25px;
img, svg{
  clip-path: circle();
  position: relative;
  bottom: 75px;
  width: auto;
  max-width: 225px;
  height: 100px;
  background-color: white;
}

`;

const Name = styled.h6`
font-size: 1rem;
font-weight:600;
margin-top: 10px;
line-height: 1.2rem;
`;

const JobTitle = styled.h6`
font-size: 1rem;
font-weight:500;
color: var(--azure);
margin-bottom: 10px;
line-height: 1.2rem;
`;

const Description = styled.p`
font-size: 1rem;
margin: 10px 0px;
color:var(--grey);
line-height: 1.5rem;
`;

const AdditionalContent = styled.div`
margin: 10px 0px;
> *{
  font-size: 0.75rem;
  font-weight:600;
  line-height: 1.2rem;
}
p:nth-child(2) {
  color: var(--azure);
}
`;

export default { SwiperWrapper, ImageContainer, Name, JobTitle, Description, AdditionalContent };
