import { useGetAllSitesQuery, useGetHeirarchyAccountsQuery, useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Spinner } from '@phmg/storybook/v1/component';
import Navbar from '@shared/components/Navbar';
import { H3 } from '@shared/components/Typography';
import { createNotification } from '@shared/utils/createNotification';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

import LocationListing from './components/LocationListing';
import S from './styles';

const LocationsPage = () => {
  const { data: siteData, isLoading: siteDataIsLoading, isError: siteDataIsError, refetch } = useGetAllSitesQuery({});
  const navigate = useNavigate();
  const [hasHeirarchy, setHasHeirarchy] = useState<boolean>(false);
  const VITE_SF_FF_ENABLED = JSON.parse(import.meta.env.VITE_SF_FF_ENABLED);

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userDataIsError,
  } = useGetUserQuery(undefined, {
    skip: !VITE_SF_FF_ENABLED,
  });
  const accountId = userData?.salesforceId;

  // Heirarchy Accounts
  const {
    data: HeirarchyAccountData,
    isLoading: HeirarchyAccountDatasIsLoading,
    isError: HeirarchyAccountDatasisError,
  } = useGetHeirarchyAccountsQuery(accountId!, { skip: !accountId });

  const isLoading = userIsLoading || siteDataIsLoading || HeirarchyAccountDatasIsLoading;
  const isError = siteDataIsError || userDataIsError || HeirarchyAccountDatasisError;

  if (!hasHeirarchy && HeirarchyAccountData && HeirarchyAccountData?.length > 1) {
    setHasHeirarchy(true);
  }
  if (!hasHeirarchy && siteData && !isLoading && HeirarchyAccountData && HeirarchyAccountData?.length <= 1) {
    navigate(`/locations/${Object.keys(siteData)[0]}`);
  }

  useEffect(() => {
    if (isError && !isLoading) {
      createNotification({
        message: 'Failed to fetch locations. Please try again.',
        type: 'error',
      });
    }
  }, [isError, refetch, isLoading]);

  return (
    <>
      <Helmet>
        <title>PHMG | Locations</title>
        <meta name="description" content="Welcome to the PHMG Client Portal." />
      </Helmet>
      <Navbar />
      <S.LocationsWrapper>
        <S.Header>
          <H3>Locations</H3>
        </S.Header>
        <>
          {isLoading && (
            <S.SpinnerContainer>
              <Spinner />
            </S.SpinnerContainer>
          )}
          {Object.values(siteData || {}).length > 0 && (
            <S.LocationsList>
              {Object.values(siteData || {})?.map((site) => (
                <LocationListing key={site.id} id={site.id} title={site.siteName} />
              ))
              
              }
            </S.LocationsList>
          )}
          {(isError || (siteData === undefined && !isLoading)) && <h5 style={{ margin: 'auto' }}>No Locations Found</h5>}
        </>
      </S.LocationsWrapper>
    </>
  );
};

export default LocationsPage;
