import styled from 'styled-components';

const TeamContainer = styled.div`
padding: 4rem;
@media only screen and (max-width: 600px) {
    }
`;

const TeamTitle = styled.h6`
  margin: 2rem 0 6rem;
`;

export default {
  TeamContainer,
  TeamTitle
};
