/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import './config/GlobalStyles.css';

import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

// User Pages
import AccountPage from './compositions/account/Page';
import ForgotPasswordPage from './compositions/auth/forgotPassword';
// Auth Pages
import LoginPage from './compositions/auth/login/Page';
import SetPasswordPage from './compositions/auth/setPassword/existingUser/Page';
import NewUserSetPassPage from './compositions/auth/setPassword/newUser/Page';
// Experimental Pages
import BrandWorkshopPage from './compositions/brandWorkshop/Page';
// Components
// Invoice Pages
import InvoicesPage from './compositions/invoices/Page';
import LocationAccountManagerPage from './compositions/location/account/locationAccountManager/Page';
import LocationBookAppointmentPage from './compositions/location/account/locationBookAppointment/Page';
import LocationRequestsPage from './compositions/location/account/locationRequests/Page';
import LocationAccountPage from './compositions/location/account/Page';
import LocationPage from './compositions/location/Page';
import LocationDetailsPage from './compositions/locationDetails/Page';
// Location Pages
import LocationsPage from './compositions/locations/Page';
// Approved Productions Pages
import ApprovedProductionsPage from './compositions/productions/approvedProductions/Page';
import MusicAndVoiceoversPage from './compositions/productions/awaitingApproval/musicAndVoiceovers/Page';
// Awaiting Apporval Pages
import AwaitingApprovalPage from './compositions/productions/awaitingApproval/Page';
import ScriptsPage from './compositions/productions/awaitingApproval/scripts/Page';
// Productions Page
import ProductionsPage from './compositions/productions/Page';
import { updateCustomer } from './config/state/globalSlices/userSlice';
import { PrivateRoute } from './shared/utils/PrivateRoute';

export const App = () => {
  const {
    data,
    isSuccess,
    isLoading,
    isError,
  } = useGetUserQuery(undefined, { skip: !localStorage.getItem('accessToken') });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (isError && !isLoading) {
    navigate('/ap/login');
  }

  if (isSuccess) {
    dispatch(updateCustomer(data));
  }

  return (
    <>
      <HelmetProvider>
        <Routes>
          <Route element={<PrivateRoute />}>
            {/* Top level account */}
            <Route path="/account" element={<AccountPage />} />

            {/* Locations */}
            <Route path="/locations" element={<LocationsPage />} />
            <Route path="/locations/:siteId" element={<LocationPage />} />
            <Route path="/locations/:siteId/invoices" element={<InvoicesPage />} />
            {/* Location Level account */}
            <Route path="/locations/:siteId/account" element={<LocationAccountPage />} />
            <Route path="/locations/:siteId/account/details" element={<LocationDetailsPage />} />
            <Route path="/locations/:siteId/account/manager" element={<LocationAccountManagerPage />} />
            <Route path="/locations/:siteId/book-appointment" element={<LocationBookAppointmentPage />} />
            <Route path="/locations/:siteId/requests" element={<LocationRequestsPage />} />
            <Route path="/locations/:siteId/brand-workshop" element={<BrandWorkshopPage />} />

            {/* Productions */}
            {/* <Route path="/locations/:siteId/productions" element={<ProductionsPage />} /> */}
            {/* Awaiting Approval Pages */}
            {/* <Route path="/locations/:siteId/productions/awaiting-approval" element={<AwaitingApprovalPage />} /> */}
            {/* <Route path="/locations/:siteId/productions/awaiting-approval/music-and-voiceovers" element={<MusicAndVoiceoversPage />} /> */}
            {/* <Route path="/locations/:siteId/productions/awaiting-approval/scripts" element={<ScriptsPage />} /> */}
            {/* Approved Productions Pages */}
            {/* <Route path="/locations/:siteId/productions/approved-productions" element={<ApprovedProductionsPage />} /> */}

          </Route>
          {/* Auth Pages */}
          <Route path="/ap/login" element={<LoginPage />} />
          <Route path="/ap/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/ap/set-password/:rc/:u" element={<SetPasswordPage />} />
          <Route path="/ap/onboarding" element={<NewUserSetPassPage />} />

          <Route path="*" element={<Navigate to="/ap/login" />} />
        </Routes>
      </HelmetProvider>
    </>
  );
};
