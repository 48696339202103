import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { store } from './config/state/store';

if (import.meta.env.VITE_NODE_ENV !== 'development') {
  Sentry.init({
    environment: import.meta.env.VITE_NODE_ENV,
    dsn: 'https://e9bd1eebb80d4411bdf1a22fd6deb28a@o4505012125302784.ingest.sentry.io/4505028620320768',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.3,
    // Session Replay
    replaysSessionSampleRate: 0.1, 
    replaysOnErrorSampleRate: 1, 
  });
}

const container = document.querySelector('#root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}>
    
      <Provider store={store}>
        <BrowserRouter>
          <App />
        
        </BrowserRouter>
        <Toaster position="bottom-left" />
      </Provider>

    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
