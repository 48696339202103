import { useGetSFUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { TeamMemberType } from '@config/state/globalSlices/apiSlice/types/salesforce';
import { Icon } from '@phmg/storybook/v1/component';

import S from './styles';

const TeamMemberSlide = (teamMember: TeamMemberType) => {
  const { 
    data: salesforceUserData, 
    isLoading: salesforceUserIsLoading 
  } = useGetSFUserQuery(teamMember?.id, { skip: !teamMember?.id });

  return (
    !salesforceUserIsLoading && (
      <>
        <S.ImageContainer>
          {salesforceUserData?.IsProfilePhotoActive ?
            <img src={salesforceUserData?.MediumPhotoUrl} alt='users profile' />
            :
            <Icon glyph='account' size='100px' />
          }
        </S.ImageContainer>
        {teamMember?.name ? <S.Name>{teamMember?.name}</S.Name> : ''}
        {teamMember?.jobTitle ? <S.JobTitle>{teamMember?.jobTitle}</S.JobTitle> : ''}
        {teamMember?.description ? <S.Description>{teamMember?.description}</S.Description> : ''}
        {
          teamMember?.brandsWorkedWith ? (
            <S.AdditionalContent>
              <p>Brands I&#39;ve worked with?</p>
              <p>{teamMember?.brandsWorkedWith}</p>
            </S.AdditionalContent>
          ) : (
            ''
          )
        }
        {
          teamMember?.favouriteSong ? (
            <S.AdditionalContent>
              <p>Favourite Song?</p>
              <p>{teamMember?.favouriteSong}</p>
            </S.AdditionalContent>
          ) : (
            ''
          )
        }
      </>
    )
  )
}

export { TeamMemberSlide as default };
