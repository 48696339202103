import { H3 } from '@shared/components/Typography';

import S from './styles';

type HeroTypes = {
    companyName?: string;
};

export default function Hero({ companyName }: HeroTypes) {
  return (
    <S.Hero>
      <H3>
                Brand workshop for
      </H3>
      < H3 >
        <strong>{companyName || 'your company'}</strong>
      </H3>
    </S.Hero>
  )
}
