import styled from 'styled-components';

const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AudioContainer = styled.div`
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
padding: 1.5rem 10rem;
margin: auto;
width: 100%;
max-width: 1200px;

text-align:center;
color: black;
background-color: white;


p{
  max-width: 700px;
  margin: 30px auto;
}
@media only screen and (max-width: 1024px) {
  padding: 1.5rem 2rem;
}
@media only screen and (max-width: 600px) {
  flex-direction: column;
  padding: 5rem 2rem;

}
`

const Player = styled.div`
margin: 30px auto;
width: 100%;
max-height: 118px;
`

export default {
  SpinnerContainer,
  AudioContainer,
  Player
};
