import { TeamMemberType } from '@config/state/globalSlices/apiSlice/types/salesforce';

import S from './styles';
import TeamCarousel from './TeamCarousel';

type TeamCarouselWithTitleTypes = {
  teamMembers: TeamMemberType[];
};

export default function TeamCarouselWithTitle({ teamMembers }: TeamCarouselWithTitleTypes) {
  return (
    <S.TeamContainer>
      <S.TeamTitle>Meet your team</S.TeamTitle>
      <TeamCarousel teamMembers={teamMembers} />
    </S.TeamContainer>
  );
}
