import api from '..';
import { SubmitAccountManagerFormParams, SubmitAccountManagerFormResponse } from '../types/locations';

export const siteMutationsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    submitAccountManagerForm: build.mutation<SubmitAccountManagerFormResponse, SubmitAccountManagerFormParams>({
      query: ({ message, subject, siteName, siteId, accountNumber }) => ({
        url: `/sites/${siteId}/account/${accountNumber}/manager`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          message,
          subject,
          siteName,
        },
      }),
    }),
  }),
});
