import { CaseTypes, RecordTypes } from '@config/state/globalSlices/apiSlice/constants';
import {
  useGetAccountManagerQuery,
  useGetAccountQuery,
  useGetCaseFilesQuery,
  useGetCaseMembersQuery,
  useGetCasesByTypeQuery,
} from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { TeamMemberType } from '@config/state/globalSlices/apiSlice/types/salesforce';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { createNotification } from '@shared/utils/createNotification';
import { useEffect, useState } from 'react';

import AudioWithTitle from './components/AudioWithTitle';
import BrandValues from './components/BrandValues';
import Cta2Card from './components/Cta2Card';
import CtaLargeCard from './components/CtaLargeCard';
import Hero from './components/Hero';
import TeamCarouselWithTitle from './components/TeamCarouselWithTitle';
// Components
import S from './styles';

const BrandWorkshopPage = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([]);

  // User
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery();

  const accountId = userData?.salesforceId as string;

  // Account
  const { 
    data: accountData, 
    isLoading: accountIsLoading, 
    isError: accountIsError } = useGetAccountQuery(accountId, { skip: !accountId });

  // Account manager
  const {
    data: accountManagerData,
    isLoading: accountManagerIsLoading,
    isError: accountManagerIsError,
  } = useGetAccountManagerQuery(accountId, { skip: !accountId });
  const accountManager = accountManagerData?.Account_Manager__r;

  const accountManagerIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Account Manager');
  if (accountManager && accountManagerIndex === -1) {
    setTeamMembers([
      ...teamMembers,
      {
        name: accountManagerData?.Account_Manager__r?.FirstName + ' ' + accountManagerData?.Account_Manager__r?.LastName,
        jobTitle: 'Account Manager',
        profileUrl: '',
        brandsWorkedWith: '',
        favouriteSong: '',
        description: '',
        id: accountManagerData?.Account_Manager__r?.Id,
      },
    ]);
  }

  // Get Production Case Data
  const {
    data: productionCasesData,
    isLoading: productionCasesIsLoading,
    isError: productionCasesIsError,
  } = useGetCasesByTypeQuery(
    { accountId: accountId, recordType: RecordTypes.PRODUCTION, caseType: CaseTypes.DEMO }, { skip: !accountId }
  );

  // Get Case Members Data
  const {
    data: caseMembersData,
    isLoading: caseMembersIsLoading,
    // isError: caseMembersIsError,
  } = useGetCaseMembersQuery({ salesforceId: accountId }, { skip: !accountId });

  // Get Team Members
  caseMembersData?.map((caseData) => {
    const producerIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Producer');
    if (caseData?.Producer__r && producerIndex === -1) {
      setTeamMembers([
        ...teamMembers,
        {
          id: caseData?.Producer__r?.Id,
          name: caseData?.Producer__r?.Name,
          jobTitle: 'Producer',
          profileUrl: '',
          brandsWorkedWith: '',
          favouriteSong: '',
          description: '',
        },
      ]);
    }
    const composerIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Composer');
    if (caseData?.Composer__r && composerIndex === -1) {
      setTeamMembers([
        ...teamMembers,
        {
          id: caseData?.Composer__r?.Id,
          name: caseData?.Composer__r?.Name,
          jobTitle: 'Composer',
          profileUrl: '',
          brandsWorkedWith: '',
          favouriteSong: '',
          description: '',
        },
      ]);
    }

    const VoiceOverIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Voiceover');
    if (caseData?.Voice_Over_Contact__r && VoiceOverIndex === -1) {
      setTeamMembers([
        ...teamMembers,
        {
          id: caseData?.Voice_Over_Contact__r?.Id,
          name: caseData?.Voice_Over_Contact__r?.Name,
          jobTitle: 'Voiceover',
          profileUrl: '',
          brandsWorkedWith: '',
          favouriteSong: '',
          description: '',
        },
      ]);
    }

    const CopywriterIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Copywriter');
    if (caseData?.Copywriter__r && CopywriterIndex === -1) {
      setTeamMembers([
        ...teamMembers,
        {
          id: caseData?.Copywriter__r?.Id,
          name: caseData?.Copywriter__r?.Name,
          jobTitle: 'Copywriter',
          profileUrl: '',
          brandsWorkedWith: '',
          favouriteSong: '',
          description: '',
        },
      ]);
    }

    const ProofreaderIndex = teamMembers.findIndex((object) => object?.jobTitle === 'Proofreader');
    if (caseData?.Proofreader__r && ProofreaderIndex === -1) {
      setTeamMembers([
        ...teamMembers,
        {
          id: caseData?.Proofreader__r?.Id,
          name: caseData?.Proofreader__r?.Name,
          jobTitle: 'Proofreader',
          profileUrl: '',
          brandsWorkedWith: '',
          favouriteSong: '',
          description: '',
        },
      ]);
    }
  });

  // Production Files Url data
  const {
    data: productionFiles,
    isLoading: productionFilesIsLoading,
    isError: productionFilesIsError,
  } = useGetCaseFilesQuery(productionCasesData?.map(({ CaseNumber }) => CaseNumber) || [], {
    skip: !productionCasesData || productionCasesData.length === 0,
  });

  // Data Checks
  const isLoading =
    userIsLoading ||
    accountIsLoading ||
    accountManagerIsLoading ||
    productionCasesIsLoading ||
    productionFilesIsLoading ||
    caseMembersIsLoading;

  useEffect(() => {
    if (userIsError && !isLoading) {
      createNotification({
        message: 'Failed to fetch your user data. Please try again.',
        type: 'error',
      });
    } else if (accountIsError && !isLoading) {
      createNotification({
        message: 'Failed to fetch your account data. Please try again.',
        type: 'error',
      });
    } else if (accountManagerIsError && !isLoading) {
      createNotification({
        message: 'Failed to fetch your account manager. Please try again.',
        type: 'error',
      });
    } else if (productionCasesIsError && !isLoading) {
      createNotification({
        message: 'Failed to fetch your production cases. Please try again.',
        type: 'error',
      });
    } else if (productionFilesIsError && !isLoading) {
      createNotification({
        message: 'Failed to fetch your production files. Please try again.',
        type: 'error',
      });
    }
  }, [userIsError, accountIsError, accountManagerIsError, productionCasesIsError, productionFilesIsError, isLoading]);

  return (
    <SidebarPageLayout pageTitle="PHMG | Brand Workshop" isLoading={isLoading}>
      {!isLoading && (
        <>
          {accountData ? <Hero companyName={accountData?.Name} /> : ''}
          {/* {hasAccountManager ? <AccountManagerHero {...accountManager} /> : ''} */}
          {accountData ? <BrandValues brandValues={accountData?.BrandValues__c} /> : ''}
          {productionFiles && (
            <S.AudioPlayer>
              {productionFiles
                ? productionFiles.map((productionFile) => {
                  const name = productionFile.slice(Math.max(0, productionFile.lastIndexOf('/') + 1)).replace(/\.[^./]+$/, '');
                  return <AudioWithTitle key={name} name={name} url={productionFile} />;
                })
                : ''}
            </S.AudioPlayer>
          )}
          {productionFiles && (
            <Cta2Card
              title="Wed love to hear what you think"
              content="Tell us whether your workshop, our delivery, and the final piece have captured what makes you unique."
              colour="green2"
              button1Text="Give feedback"
              button2Text="Approve"
            />
          )}
          {teamMembers ? <TeamCarouselWithTitle teamMembers={teamMembers} /> : ''}
          {productionFiles && (
            <CtaLargeCard
              title="Wed love to hear what you think"
              content="Tell us whether your workshop, our delivery, and the final piece have captured what makes you unique."
              colour="green2"
              button1Text="Give feedback"
              button2Text="Approve"
            />
          )}
        </>
      )}
    </SidebarPageLayout>
  );
};

export default BrandWorkshopPage;
