import { H4, H6 } from '@shared/components/Typography';

import S from './styles';

type BrandValuesTypes = {
    brandValues: string;
};

export default function BrandValues(props: BrandValuesTypes) {
  const { brandValues } = props;
  return (
    <S.BrandValuesContainer>
      <H4>Your Brand Values</H4>
      <p> These values will strategically underpin your audio brand. This is your sonic blueprint.</p>
      {brandValues ? <S.ButtonsContainer><S.BrandValueButton><H6>{brandValues}</H6></S.BrandValueButton></S.ButtonsContainer> : ''}
    </S.BrandValuesContainer>
  )
}
