import { store } from '@config/state/store';

export const getLocationMenuItems = (siteId: string, hasProductions: boolean) => {
  const globalStore = store.getState();
  // const VITE_SF_FF_ENABLED = JSON.parse(import.meta.env.VITE_SF_FF_ENABLED);
  const hasSalesforceId = Boolean(globalStore.user.salesforceId);
  // const showYourProductions = VITE_SF_FF_ENABLED && hasSalesforceId && hasProductions;

  if (siteId) {
    return [
      {
        href: `/locations/${siteId}`,
        title: 'Dashboard',
        showOnMobile: false,
        enabled: true,
        color: '#FF609E',
        glyph: 'dashboard',
      },

      {
        href: '/',
        title: 'The Studio',
        showOnMobile: false,
        enabled: false,
        color: '#007CFF',
      },

      {
        href: `/locations/${siteId}/account`,
        title: 'Brand workshop',
        showOnMobile: false,
        enabled: false,
        color: '#FF9300',
      },
      {
        href: '/',
        title: 'Products',
        showOnMobile: false,
        enabled: false,
        color: '#5800FF',
      },
      {
        href: `/locations/${siteId}/invoices`,
        title: 'Invoices',
        showOnMobile: true,
        enabled: true,
        color: '#77CE5A',
        glyph: 'flat-invoices',
      },

      {
        href: `/locations/${siteId}/brand-workshop`,
        title: 'Brand workshop',
        showOnMobile: true,
        enabled: hasSalesforceId,
        color: '#77CE5A',
        glyph: 'flat-pencil',
      },

      {
        href: `/locations/${siteId}/productions`,
        title: 'Your productions',
        showOnMobile: true,
        enabled: hasProductions,
        color: '#FF3D1D',
        glyph: 'products',
      },

      {
        href: `/locations/${siteId}/account`,
        title: 'Location details',
        showOnMobile: true,
        enabled: true,
        color: '#8035FF',
        glyph: 'location',
      },

      {
        href: `/locations/${siteId}/account/manager`,
        title: 'Account Manager',
        showOnMobile: true,
        enabled: true,
        color: '#FF3D1D',
        glyph: 'message',
      },
    ];
  } else {
    return [];
  }
};
