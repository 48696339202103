import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

const Card = styled.div`
    background-color: var(--white);
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    color: inherit;
    text-decoration: none;
    transition: 0.25s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 4rem 8rem;
    width: auto;
    margin: 50px;
    min-height: 100px;

    h6:nth-child(1){
        font-weight: 900;
    }

    &.green {
        background-color: var(--deep-teal);
        color: var(--lime);
    }

    &.green2 {
        background-image: linear-gradient( to right, var(--turquise), var(--lime) );
        color: var(--black);
    }

    &.purple {
        background-color: #380044;
        color: var(--pink);
    }

    @media (max-width: ${size.large}) {
    flex-direction: column;
    padding: 3rem 1rem;
  }

`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 600px;
   margin: auto;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: auto;
    margin: 3rem auto 0rem;

    > * {
            margin-right: 1rem;
        }

    @media (max-width: ${size.large}) {
        width: auto;
        margin-top: 1rem;
        justify-content: flex-start;
        flex-direction: column;
        > * {
            margin-right: 1rem;

            > *{
                text-align: center;
            }

        }
  }
`;

export default {
  Card,
  Text,
  Buttons
};
